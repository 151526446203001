// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".success_icon_1Y1RE{width:2.13333rem;height:2.13333rem;display:block;margin:2.93333rem auto 0}.success_successText_1U6DC{margin:.58667rem 0 .26667rem 0;font-size:.53333rem;font-weight:500}.success_successText_1U6DC,.success_tip_3G23-{text-align:center}.success_btnWrap_3pnmW{position:fixed;bottom:.96rem;left:0;width:100%;box-sizing:border-box;padding:0 .53333rem;display:flex;justify-content:space-between}.success_btnWrap_3pnmW .success_btn_2wQA0{width:4.32rem;height:1.06667rem;line-height:1.06667rem;text-align:center;background:#212121;border-radius:.85333rem;font-size:.42667rem}", ""]);
// Exports
exports.locals = {
	"icon": "success_icon_1Y1RE",
	"successText": "success_successText_1U6DC",
	"tip": "success_tip_3G23-",
	"btnWrap": "success_btnWrap_3pnmW",
	"btn": "success_btn_2wQA0"
};
module.exports = exports;
