<template>
  <div>
    <Back />
    <img :class="$style.icon" src="@/assets/img/pay-success.png" />
    <p :class="$style.successText">付款成功</p>
    <p :class="$style.tip">您可以在订单详情中跟踪订单物流信息</p>
    <div :class="$style.btnWrap">
      <span :class="$style.btn" @click="handleBack">返回</span>
      <span :class="$style.btn" @click="handle2Order">查看详情</span>
    </div>
  </div>
</template>

<script>
import Back from "@/components/Back";
export default {
  components: {
    Back,
  },
  data() {
    return {};
  },
  methods: {
    handleBack() {
      this.$router.push({ path: "/" });
    },
    handle2Order() {
      this.$router.push({ path: "/order" });
    },
  },
};
</script>

<style lang="scss" module>
.icon {
  width: 80px;
  height: 80px;
  display: block;
  margin: 110px auto 0;
}
.successText {
  margin: 22px 0 10px 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.tip {
  text-align: center;
}
.btnWrap {
  position: fixed;
  bottom: 36px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .btn {
    width: 162px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #212121;
    border-radius: 32px;
    font-size: 16px;
  }
}
</style>
